import { CheersUP } from "./CheersUP";
// import { CheersUPBadge } from "./CheersUPBadge";
import { CheersUPEmoji } from "./CheersUPEmoji";

export const Part3 = () => {
  return (
    <div className="bg-black px-[60px]">
      <div className="max-w-[1440px] mx-auto">
        <CheersUP className="index-v2-cup" />
        <CheersUPEmoji className="mt-[60px] index-v2-emoji" />
        {/* <CheersUPBadge className="mt-[60px] index-v2-badge" /> */}
      </div>
    </div>
  );
};
