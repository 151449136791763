import { useMs } from "@/hooks/useMs";
import classnames from "classnames";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { scrollToY } from "utils";
import Logo from "../../public/img/index-v2/logo-transparent.svg";
import { ArrowDownButton } from "./arrow-button";

export const Part1 = ({
  className = "",
  scale,
  revealed,
  wHeight,
  loadingEnd,
}) => {
  const { t } = useTranslation("home");
  const { ms } = useMs({ maxMs: 5000 });

  const handleStartClick = () => {
    scrollToY(wHeight);
  };

  return (
    <div
      className={classnames(
        "w-full min-w-[1100px]",
        [revealed ? "relative h-screen bg-black" : "fixed top-0 bottom-0"],
        className
      )}
      style={{ height: wHeight }}
    >
      <div className="h-full flex items-center">
        <div className="ml-[180px]">
          <div
            className={classnames(
              "text-[60px] leading-[76px] xl:text-[80px] xl:leading-[100px] 3xl:text-[106px] 3xl:leading-[136px] font-DIN_Alternate",
              {
                "animate-easein direction-reverse": loadingEnd,
              }
            )}
          >
            {t("15")}
            <br />
            {t("16")}
          </div>
          <div
            className={classnames("flex mt-[40px]", {
              "animate-easein direction-reverse": loadingEnd,
              paused: ms < 3100,
            })}
          >
            <div className="h-px w-[80px] bg-white mt-[12px] mr-[32px]"></div>
            <div className="text-[14px] leading-[22px] xl:text-[18px] xl:leading-[26px] 3xl:text-[24px] 3xl:leading-[34px] font-medium tracking-[0.06em]">
              {t("1")}
              <br />
              {t("2")}
            </div>
          </div>
          <div
            className={classnames("mt-[100px]", {
              "animate-easein direction-reverse": loadingEnd,
              paused: ms < 3200,
            })}
          >
            <ArrowDownButton onClick={handleStartClick}>
              <div className="w-full h-[56px] absolute top-0 left-0 z-[2] bg-transparent"></div>
              <div className="relative">{t("3")}</div>
            </ArrowDownButton>
          </div>
        </div>
      </div>

      {!revealed && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          {/* top bottom bg */}
          <div
            className={classnames(
              "relative transition duration-75 ease-linear",
              'before:content-[""] before:absolute before:w-[400vw] before:h-[200vh] before:bg-black before:top-[-200vh] before:left-[-200vw] before:translate-y-[2px]',
              'after:content-[""] after:absolute after:w-[400vw] after:h-[200vh] after:bg-black after:left-[-200vw] after:translate-y-[-2px]'
            )}
            style={{ transform: `scale(${scale})` }}
          >
            {/* left right bg */}
            <div
              className={classnames(
                'before:content-[""] before:absolute before:w-[200vw] before:h-[200vh] before:bg-black before:top-[-100vh] before:left-[-200vw] before:translate-x-[2px]',
                'after:content-[""] after:absolute after:w-[200vw] after:h-[200vh] after:bg-black after:top-[-100vh] after:translate-x-[-2px]'
              )}
            >
              <Logo className="align-top" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
