import classNames from "classnames";
import Logo from "../../public/img/index-v2/logo-white.svg";
import LightLt from "../../public/img/index-v2/light-lt.svg";
import LightRt from "../../public/img/index-v2/light-rt.svg";
import LightLb from "../../public/img/index-v2/light-lb.svg";
import LightRb from "../../public/img/index-v2/light-rb.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useMs } from "@/hooks/useMs";
import { useRouter } from "next/router";

export const Part2 = ({ revealed, className = "", wHeight }) => {
  const { t } = useTranslation("home");
  const { ms, startMs, resetMs } = useMs({});

  useEffect(() => {
    if (revealed) {
      startMs();
    } else {
      resetMs();
    }
  }, [revealed]);

  return (
    <div
      className={classNames(
        "bg-black flex items-center justify-center text-[40px] xl:text-[50px] font-DIN_Alternate font-bold",
        className
      )}
      style={{
        marginTop: !revealed ? 2 * wHeight : -100,
        height: wHeight,
      }}
    >
      <div className={classNames("flex items-center justify-center")}>
        <div
          className={classNames("text-right", {
            "animate-scalefadein origin-right": revealed,
            paused: ms < 300,
          })}
        >
          <div>{t("4")}</div>
          <div className="mt-[8px]">{t("6")}</div>
        </div>
        <div className="mx-[24px] relative">
          <Logo
            className={classNames({
              "animate-scalefadein origin-center": revealed,
            })}
          />
          <LightLt
            className={classNames("absolute left-[-180px] top-[-100px]", {
              "animate-scalefadein origin-bottom-right": revealed,
            })}
          />
          <LightRt
            className={classNames("absolute right-[-220px] top-[-130px]", {
              "animate-scalefadein origin-bottom-left": revealed,
            })}
          />
          <LightLb
            className={classNames("absolute left-[-240px] bottom-[-120px]", {
              "animate-scalefadein origin-top-right": revealed,
            })}
          />
          <LightRb
            className={classNames("absolute right-[-200px] bottom-[-110px]", {
              "animate-scalefadein origin-top-left": revealed,
            })}
          />
        </div>
        <div
          className={classNames({
            "animate-scalefadein origin-left": revealed,
            paused: ms < 300,
          })}
        >
          <div>{t("5")}</div>
          <div className="mt-[8px]">{t("7")}</div>
        </div>
      </div>
    </div>
  );
};
