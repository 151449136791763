import { BlackBox } from "./black-box";
import { WhiteBox } from "./white-box";
import { getNftCollectionUrl } from "../../utils";
import {
  CHEERS_UP_OPENSEA_COLLECTION_NAME,
  CHEERS_UP_PERIOD_VIDEO_URL,
} from "../../constants";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { LazyVideo } from "../lazy-media";

export const CheersUP = ({ className = "", ...rest }) => {
  const { t } = useTranslation("home");

  return (
    <div className={classNames("flex", className)} {...rest}>
      <div className="flex-1 mr-[4px]">
        <div className="aspect-w-1 aspect-h-1">
          <BlackBox>
            <nft-viewer
              class="w-full"
              type="video"
              src={CHEERS_UP_PERIOD_VIDEO_URL}
              autoplay
              muted
            ></nft-viewer>
          </BlackBox>
        </div>
      </div>

      <div className="flex-1">
        <div className="aspect-w-1 aspect-h-1">
          <WhiteBox
            title="Cheers UP"
            desc={t("8")}
            openseaLink={getNftCollectionUrl(CHEERS_UP_OPENSEA_COLLECTION_NAME)}
            learnMoreLink="/cheersup"
            learnMoreText={t("12")}
            learnMoreATarget="_blank"
            footer={
              <div className="h-full border-0 border-t-2 border-solid border-black flex">
                <div className="flex-1 flex flex-col items-center justify-center">
                  <div className="font-DIN_Alternate font-bold text-[24px] xl:text-[34px]">
                    5,000
                  </div>
                  <div className="text-[12px] xl:text-[14px] font-normal mt-[4px] text-[#9499A0]">
                    items
                  </div>
                </div>

                <div className="h-full w-[2px] bg-black"></div>

                <div className="flex-1 flex flex-col items-center justify-center">
                  <div className="font-DIN_Alternate font-bold text-[24px] xl:text-[34px]">
                    0.12 ETH
                  </div>
                  <div className="text-[12px] xl:text-[14px] font-normal mt-[4px] text-[#9499A0]">
                    only allow list
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};
