import { styled } from "@mui/system";
import classNames from "classnames";
import Script from "next/script";
import { useEffect, useRef } from "react";

const mapNumber = (X, A, B, C, D) => ((X - A) * (D - C)) / (B - A) + C;
// from http://www.quirksmode.org/js/events_properties.html#position
const getMousePos = (e) => {
  let posx = 0;
  let posy = 0;
  if (!e) e = window.event;
  if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  } else if (e.clientX || e.clientY) {
    posx =
      e.clientX +
      document.body.scrollLeft +
      document.documentElement.scrollLeft;
    posy =
      e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }
  return { x: posx, y: posy };
};
// Generate a random float.
const getRandomFloat = (min, max) =>
  (Math.random() * (max - min) + min).toFixed(2);

class HoverImgFx3 {
  DOM: {
    reveal?: HTMLDivElement;
    revealInner?: any;
    revealImg?: any;
    letters?: any[];
    el?: any;
  };
  positionElement: (ev: any) => void;
  mouseenterFn: (ev: any) => void;
  mousemoveFn: (ev: any) => number;
  mouseleaveFn: () => void;
  tl: any;

  constructor(el) {
    const { charming } = window as any;
    this.DOM = { el: el };
    this.DOM.reveal = document.createElement("div");
    this.DOM.reveal.className = "hover-reveal";
    this.DOM.reveal.style.overflow = "hidden";
    this.DOM.reveal.innerHTML = `<div class="hover-reveal__inner"><div class="hover-reveal__img" style="background-image:url(${this.DOM.el.dataset.img})"></div></div>`;
    this.DOM.el.appendChild(this.DOM.reveal);
    this.DOM.revealInner = this.DOM.reveal.querySelector(
      ".hover-reveal__inner"
    );
    this.DOM.revealInner.style.overflow = "hidden";
    this.DOM.revealImg =
      this.DOM.revealInner.querySelector(".hover-reveal__img");
    charming(this.DOM.el);
    this.DOM.letters = [...this.DOM.el.querySelectorAll("span")];
    this.initEvents();
  }
  initEvents() {
    this.positionElement = (ev) => {
      const mousePos = getMousePos(ev);
      const docScrolls = {
        left: document.body.scrollLeft + document.documentElement.scrollLeft,
        top: document.body.scrollTop + document.documentElement.scrollTop,
      };
      this.DOM.reveal.style.top = `${mousePos.y + 20 - docScrolls.top}px`;
      this.DOM.reveal.style.left = `${mousePos.x + 20 - docScrolls.left}px`;
    };
    this.mouseenterFn = (ev) => {
      this.positionElement(ev);
      this.showImage();
      this.animateLetters();
    };
    this.mousemoveFn = (ev) =>
      requestAnimationFrame(() => {
        this.positionElement(ev);
      });
    this.mouseleaveFn = () => {
      this.hideImage();
    };

    this.DOM.el.addEventListener("mouseenter", this.mouseenterFn);
    this.DOM.el.addEventListener("mousemove", this.mousemoveFn);
    this.DOM.el.addEventListener("mouseleave", this.mouseleaveFn);
  }
  showImage() {
    const { TweenMax, Expo, TimelineMax } = window as any;
    TweenMax.killTweensOf(this.DOM.revealInner);
    TweenMax.killTweensOf(this.DOM.revealImg);

    this.tl = new TimelineMax({
      onStart: () => {
        this.DOM.reveal.style.opacity = "1";
        TweenMax.set(this.DOM.el, { zIndex: 1000 });
      },
    })
      .add("begin")
      .set([this.DOM.revealInner, this.DOM.revealImg], {
        transformOrigin: "50% 100%",
      })
      .add(
        new TweenMax(this.DOM.revealInner, 0.4, {
          ease: Expo.easeOut,
          startAt: { x: "50%", y: "120%", rotation: 50 },
          x: "0%",
          y: "0%",
          rotation: 0,
        }),
        "begin"
      )
      .add(
        new TweenMax(this.DOM.revealImg, 0.4, {
          ease: Expo.easeOut,
          startAt: { x: "-50%", y: "-120%", rotation: -50 },
          x: "0%",
          y: "0%",
          rotation: 0,
        }),
        "begin"
      )
      .add(
        new TweenMax(this.DOM.revealImg, 0.7, {
          ease: Expo.easeOut,
          startAt: { scale: 2 },
          scale: 1,
        }),
        "begin"
      );
  }
  hideImage() {
    const { TweenMax, Expo, TimelineMax } = window as any;
    TweenMax.killTweensOf(this.DOM.revealInner);
    TweenMax.killTweensOf(this.DOM.revealImg);

    this.tl = new TimelineMax({
      onStart: () => {
        TweenMax.set(this.DOM.el, { zIndex: 999 });
      },
      onComplete: () => {
        TweenMax.set(this.DOM.el, { zIndex: "" });
        TweenMax.set(this.DOM.reveal, { opacity: 0 });
      },
    })
      .add("begin")
      .add(
        new TweenMax(this.DOM.revealInner, 0.6, {
          ease: Expo.easeOut,
          y: "-120%",
          rotation: -5,
        }),
        "begin"
      )
      .add(
        new TweenMax(this.DOM.revealImg, 0.6, {
          ease: Expo.easeOut,
          y: "120%",
          rotation: 5,
          scale: 1.2,
        }),
        "begin"
      );
  }
  animateLetters() {
    const { TweenMax, Expo } = window as any;
    TweenMax.killTweensOf(this.DOM.letters);
    TweenMax.set(this.DOM.letters, { opacity: 0 });
    TweenMax.staggerTo(
      this.DOM.letters,
      0.2,
      {
        ease: Expo.easeOut,
        startAt: { x: "100%" },
        x: "0%",
        opacity: 1,
      },
      0.03
    );
  }
}
const _HoverImg = ({ className = "", text, img, ...rest }) => {
  const domRef = useRef();
  const doneRef = useRef(false);

  const init = () => {
    if (doneRef.current) {
      return;
    }

    const { charming, TweenMax, Expo } = window as any;
    if (charming && TweenMax && Expo) {
      new HoverImgFx3(domRef.current);
      doneRef.current = true;
    }
  };

  useEffect(() => {
    init();
  });

  return (
    <>
      <Script src="js/charming.min.js" onLoad={init} />
      <Script src="js/TweenMax.min.js" onLoad={init} />
      <button
        className={classNames(
          "btn-unstyled block__link relative font-semibold underline",
          className
        )}
        data-img={img}
        ref={domRef}
        {...rest}
      >
        {text}
      </button>
    </>
  );
};

export const HoverImg = styled(_HoverImg)(() => {
  return {
    "&:hover": {
      background:
        "linear-gradient(90deg, #0096FF 0%, #BC2AEF 53.65%, #FF2800 100%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      "background-clip": "text",
      "text-fill-color": "transparent",
    },

    ".hover-reveal": {
      position: "fixed",
      width: "330px",
      height: "222px",
      top: 0,
      left: 0,
      pointerEvents: "none",
      opacity: 0,
    },

    ".hover-reveal__inner, .hover-reveal__img": {
      width: "100%",
      height: "100%",
      position: "relative",
    },

    ".hover-reveal__deco": {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: "#181314",
    },

    ".hover-reveal__img": {
      backgroundSize: "contain",
      backgroundPosition: "50% 50%",
    },
  };
});
