import TopIcon from "../../public/img/index-v2/top.svg";
import { scrollToY } from "../../utils";

export const Top = () => {
  const handleClick = () => {
    console.log(1);
    scrollToY(0);
  };

  return (
    <div className="bg-black pt-[60px] px-[60px]">
      <button
        className="mx-auto btn-unstyled w-full max-w-[1440px] h-[100px] rounded-[40px] border-2 border-solid border-white flex items-center justify-center transition hover:bg-white group"
        onClick={handleClick}
      >
        <TopIcon className="transition group-hover:text-black" />
      </button>
    </div>
  );
};
