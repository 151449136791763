export const BlackBox = ({ children, ...rest }) => {
  return (
    <div
      className="w-full h-full flex items-center justify-center bg-center bg-contain bg-no-repeat bg-[url(/img/index-v2/bg2.png)]"
      {...rest}
    >
      <div className="relative w-[76%] h-[76%] rounded-[30px] overflow-hidden">
        {children}
      </div>
    </div>
  );
};
