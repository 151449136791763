import { BlackBox } from "./black-box";
import { WhiteBox } from "./white-box";
import { getNftCollectionUrl } from "../../utils";
import { CHEERS_UP_EMOJI_OPENSEA_COLLECTION_NAME } from "../../constants";
import classNames from "classnames";
import emojiImg from "../../public/img/index-v2/emoji.png";
import Image from "next/legacy/image";
import { HoverImg } from "./hover-img";
import { useTranslation } from "next-i18next";
import { useWeb3React } from "@web3-react/core";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";

export const CheersUPEmoji = ({ className = "", ...rest }) => {
  const { t } = useTranslation("home");
  const { isActive } = useWeb3React();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const handleLearnMoreClick = () => {
    if (!isActive) {
      enqueueSnackbar("Please connect wallet first.", {
        variant: "info",
      });
      return;
    }

    router.push("/collection?tab=emoji");
  };

  return (
    <div className={classNames("flex", className)} {...rest}>
      <div className="flex-1">
        <div className="aspect-w-1 aspect-h-1">
          <WhiteBox
            title="Cheers UP Emoji"
            desc={
              <div>
                <div>{t("9")}</div>
                <div>
                  {t("10")} &nbsp;
                  <HoverImg
                    text="Discord"
                    img="/img/index-v2/emoji-discord.png"
                  />
                  ,&nbsp;
                  <HoverImg text="bilibili" img="/img/index-v2/emoji-b.png" />
                  ,&nbsp;Etc
                </div>
              </div>
            }
            openseaLink={getNftCollectionUrl(
              CHEERS_UP_EMOJI_OPENSEA_COLLECTION_NAME
            )}
            learnMoreText={t("13")}
            learnMoreType="button"
            learnMoreOnClick={handleLearnMoreClick}
            footer={
              <div className="h-full border-0 border-t-2 border-solid border-black flex">
                <div className="flex-1 flex flex-col items-center justify-center">
                  <div className="font-DIN_Alternate font-bold text-[24px] xl:text-[34px]">
                    53
                  </div>
                  <div className="text-[12px] xl:text-[14px] font-normal mt-[4px] text-[#9499A0]">
                    items
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>

      <div className="flex-1 ml-[4px]">
        <div className="aspect-w-1 aspect-h-1">
          <BlackBox>
            <Image src={emojiImg} layout="fill" alt="" />
          </BlackBox>
        </div>
      </div>
    </div>
  );
};
