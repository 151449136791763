import Head from "next/head";
import { useEffect, useRef, useState } from "react";
import {
  FlowmapEffect,
  Part1,
  Part2,
  Part3,
  Top,
  LoadingModal,
} from "@/components/index-v2";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import classNames from "classnames";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "home"])),
    },
  };
}

export default function Index() {
  const [initScale, setInitScale] = useState(66);
  const [scale, setScale] = useState(initScale);
  const lastScaleRef = useRef<number>();
  const [revealed, setRevealed] = useState(false);
  const [loadingEnd, setLoadingEnd] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();
  const [wHeight, setWHeight] = useState(2000);

  const handleScroll = () => {
    const { scrollTop } = document.scrollingElement;
    const h = window.innerHeight;

    // console.log("handleScroll", document.scrollingElement.scrollTop, h);

    let newScale;
    if (scrollTop < h) {
      const _scale = ((h - scrollTop) / h) * initScale;
      // console.log("_scale", _scale);
      newScale = Math.min(initScale, _scale);
      newScale = Math.max(1, _scale);
      setRevealed(false);
    } else {
      newScale = 1;
      setRevealed(true);
    }

    newScale = Math.floor(newScale);
    // console.log("scale", newScale);
    setScale(newScale);

    // console.log({
    //   "lastScale.current": lastScaleRef.current,
    //   newScale,
    // });
  };

  const computeInitScale = () => {
    const wH = window.innerHeight;
    const wW = window.innerWidth;

    const hScale = wH / 40;
    const wScale = wW / 40;

    console.log({
      hScale,
      wScale,
    });

    const _scale = Math.max(hScale, wScale);

    console.log("computeInitScale", _scale);
    setInitScale(_scale);
  };

  const handleResize = () => {
    setWHeight(window.innerHeight);
    computeInitScale();
  };

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 2000);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    computeInitScale();

    document.body.style.overflow = "hidden";

    window.addEventListener("resize", handleResize);

    setTimeout(() => {
      document.body.style.overflow = "";
      setLoadingEnd(true);
    }, 2800);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    // scrollToY(0);
    setScale(initScale);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [initScale]);

  useEffect(() => {
    lastScaleRef.current = scale;
  }, [scale]);

  return (
    <>
      <Head>
        <title>CryptoNatty: A Crypto Native Culture & Content Platform</title>
        <meta
          name="description"
          content="A Crypto Native Culture & Content Platform."
        />
        <meta name="keywords" content="CryptoNatty, Cheers UP, bilibili nft" />
      </Head>
      <style>
        {`
        body{
          background-color: black;
          min-width: 1100px;
        }`}
      </style>
      <FlowmapEffect
        imgSize={[2880, 1650]}
        imgSrc="/img/index-v2/bg.png"
        className={classNames(
          "fixed top-0 left-0 right-0 bottom-0 index-v2-canvas-wrapper",
          { invisible: revealed }
        )}
      />
      <div className="w-full min-w-[1100px] text-white pt-[100px] relative z-[1]">
        <Part1
          scale={scale}
          revealed={revealed}
          loadingEnd={loadingEnd}
          wHeight={wHeight}
        />
        <Part2 revealed={revealed} wHeight={wHeight} />
        <Part3 />
        <Top />
        <div className="h-[200px] bg-black"></div>
      </div>
      <LoadingModal open={!loadingEnd} />
    </>
  );
}
