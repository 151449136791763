import OpenseaIcon from "../../public/icon/opensea.svg";
import Link from "next/link";
import { ArrowRightButton } from "./arrow-button";
import classnames from "classnames";

const OpenSeaLink = ({ href }) => {
  return (
    (<Link passHref href={href} target="_blank">

      <div className="flex items-center transition hover:opacity-80">
        <OpenseaIcon />
        <div className="font-bold underline ml-[4px]">Opensea</div>
      </div>

    </Link>)
  );
};

export const WhiteBox = ({
  title,
  desc,
  openseaLink = "",
  learnMoreLink = "",
  learnMoreATarget = "",
  learnMoreDisabled = false,
  learnMoreText,
  learnMoreType = "link", // link | button
  learnMoreOnClick = () => {},
  footer = null,
  ...rest
}) => {
  return (
    <div
      className="text-black rounded-[40px] overflow-hidden bg-center bg-contain bg-no-repeat bg-[url(/img/index-v2/bg-w.png)]"
      {...rest}
    >
      <div className="relative h-full flex flex-col">
        <div className="flex-1 px-[12.16%] flex justify-center flex-col">
          <div className="text-[44px] xl:text-[60px] font-bold font-DIN_Alternate">
            {title}
          </div>
          <div className="mt-[16px] xl:mt-[24px] text-[12px] leading-[18px] xl:text-[16px] xl:leading-[24px] font-normal">
            {desc}
          </div>

          <div className="flex items-center mt-[24px] xl:mt-[32px] text-[12px] xl:text-[16px]">
            <div className="text-[#9499A0] mr-[8px]">Buy On</div>
            <OpenSeaLink href={openseaLink} />
          </div>

          <div className="mt-[40px] xl:mt-[60px]">
            {learnMoreDisabled ? (
              <ArrowRightButton disabled>
                <div className="relative">{learnMoreText}</div>
              </ArrowRightButton>
            ) : learnMoreType === "link" ? (
              (<Link
                passHref
                href={learnMoreLink}
                target={learnMoreATarget}
                className="inline-block">

                <ArrowRightButton>
                  <div className="relative">{learnMoreText}</div>
                </ArrowRightButton>

              </Link>)
            ) : (
              <ArrowRightButton onClick={learnMoreOnClick}>
                <div className="relative">{learnMoreText}</div>
              </ArrowRightButton>
            )}
          </div>
        </div>
        <div className="h-[15.2%]">{footer}</div>
      </div>
    </div>
  );
};
