import classnames from "classnames";
import ArrowDownIcon from "../../public/img/index-v2/arrow-down.svg";
import ArrowDown2Icon from "../../public/img/index-v2/arrow-down2.svg";

export const ArrowDownButton = ({ children, className = "", ...rest }) => {
  return (
    <button
      className={classnames(
        "btn-unstyled text-[18px] font-medium flex items-center rounded-[56px] h-[56px] relative group",
        className
      )}
      {...rest}
    >
      <div className="absolute w-full h-full bg-black rounded-[56px] group-hover:scale-[1.15] transition">
        <div className="w-[40px] h-[40px] pt-[2px] justify-center mt-[8px] ml-[8px] bg-white rounded-full overflow-hidden">
          <div>
            <ArrowDown2Icon className="align-top leading-none origin-top w-[36px] opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100 transition" />
          </div>
          <ArrowDownIcon
            width={36}
            className="align-top transition translate-y-[-36px] group-hover:translate-y-0"
          />
        </div>
      </div>
      <div className="ml-[64px] pr-[30px]">{children}</div>
    </button>
  );
};

export const ArrowRightButton = ({
  children,
  className = "",
  disabled = false,
  ...rest
}) => {
  return (
    <button
      className={classnames(
        "btn-unstyled text-[18px] font-medium flex items-center rounded-[56px] h-[56px] relative group",
        disabled ? "text-[#C9CCD0]" : "text-white",
        { " cursor-not-allowed": disabled },
        className
      )}
      {...rest}
    >
      <div
        className={classnames(
          "absolute w-full h-full  rounded-[56px] transition z-0",
          disabled ? "bg-[#F1F2F3]" : "bg-black",
          {
            "group-hover:scale-[1.15]": !disabled,
          }
        )}
      >
        <div className="w-[40px] h-[40px] pt-[2px] pl-[2px] mt-[8px] ml-[8px] bg-white rounded-full whitespace-nowrap overflow-hidden">
          <div
            className={classnames(
              "w-[36px] h-[36px] inline-block opacity-0 origin-left translate-y-[-19px] scale-0 transition",
              {
                "group-hover:opacity-100 group-hover:scale-100": !disabled,
              }
            )}
          >
            <ArrowDown2Icon className="align-top leading-none w-[36px] h-[36px] origin-center rotate-[-90deg]" />
          </div>
          <div
            className={classnames(
              "inline-block transition translate-x-[-36px]",
              {
                "opacity-30": disabled,
                "group-hover:translate-x-[2px]": !disabled,
              }
            )}
          >
            <ArrowDownIcon
              width={36}
              className="float-left align-top rotate-[-90deg]"
            />
          </div>
        </div>
      </div>
      <div className="ml-[64px] pr-[30px]">{children}</div>
    </button>
  );
};
