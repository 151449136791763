import Image from "next/legacy/image";
import React from "react";
import logoLoadingImg from "../../public/img/index-v2/logo-loading.gif";

export const LoadingModal = ({ open }) => {
  return (
    !!open && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black z-[100]">
        <Image
          width={80}
          height={80}
          src={logoLoadingImg}
          alt=""
          priority
          quality={100}
        />
      </div>
    )
  );
};
